import React from "react";
import "./index.scss";
import approvalMax from '../../assets/images/avatars/approvalmax.jpg'
import visier from '../../assets/images/avatars/visier.jpg'
import yva from '../../assets/images/avatars/yva.jpg'
import sberbank from '../../assets/images/avatars/sberbank.jpg'
import hermes from '../../assets/images/avatars/hermes.jpg'
import pickpoint from '../../assets/images/avatars/pickpoint.jpg'

const ExperienceList = () => {

    const positions = [
        {
            logoUrl: `${approvalMax}`,
            companyUrl : "https://www.approvalmax.com/",
            title : "Senior Backend Developer",
            company : "ApprovalMax LLC",
            location : "London, England, United Kingdom · Remote",
            period : "Jun 2023 - Sep 2023 · 4 mos",
            responsibilities : [
                "I actively participated in the development of services that facilitated the exchange of financial documents via the Xero accounting platform.",
                "I designed and developed services with the goal of automating tax deduction calculations in accordance with the CIS (Construction Industry Scheme) system for clients based in the United Kingdom.",
                "I was actively engaged in integrating with the Airwallex API, enabling online payments with automatic currency conversion, differing from the recipient's currency."
            ]
        },
        {
            logoUrl: `${visier}`,
            companyUrl : "https://www.visier.com/",
            title : "Staff Software Engineer",
            company : "Visier Inc. · Contract",
            location : "Vancouver, British Columbia, Canada · Remote",
            period : "Apr 2022 - Feb 2023 · 11 mos",
            responsibilities : [
                "Developed modules for automatization of the entities creation on new instances of cloud-based platform by creating a module for rolling out state on the platform. Increased the speed of deployment of new instances in SaaS and on premise by 30%.",
                "Provided high load services to calculate Collaboration Circles between employees for 360 feedback smart survey from scratch. Collaboration circles accuracy reaches 90% which provides the quality of360 feedback survey.",
                "I took part in the development of crawler schedulers designed to extract and analyze the raw data of employees activity from various data sources, such as Office365, Jira, Slack, Zoom.",
                "I have participated in the development of backend services for generation of all kind of reports provided to our customers in the system.",
                "Developed and optimized the logic for generation Smart Surveys based on Collaboration Circles between employees."
            ]
        },
        {
            logoUrl: `${yva}`,
            companyUrl : "https://www.linkedin.com/company/yva/",
            title : "Senior Software Engineer",
            company : "Yva.ai · Full-time",
            location : "Santa Clara County, California, United States · Remote",
            period : "Apr 2021 - Feb 2023 · 1 yr 11 mos",
            responsibilities : [
                "Developed modules for automatization of the entities creation on new instances of cloud-based platform by creating a module for rolling out state on the platform. Increased the speed of deployment of new instances in SaaS and on premise by 30%.",
                "Provided high load services to calculate Collaboration Circles between employees for 360 feedback smart survey from scratch. Collaboration circles accuracy reaches 90% which provides the quality of360 feedback survey.",
                "I took part in the development of crawler schedulers designed to extract and analyze the raw data of employees activity from various data sources, such as Office365, Jira, Slack, Zoom.",
                "I have participated in the development of backend services for generation of all kind of reports provided to our customers in the system.",
                "Developed and optimized the logic for generation Smart Surveys based on Collaboration Circles between employees."
            ]
        },
        {
            logoUrl: `${sberbank}`,
            companyUrl : "https://www.sberbank.com/",
            title : "Senior Software Developer",
            company : "Sberbank",
            location : "Moscow City, Russia · On-site",
            period : "Full-time · 1 yr 7 mosFull-time · 1 yr 7 mos",
            responsibilities : [
                "I played a key role in establishing a platform designed to facilitate insurance transactions encompassing various insurance types such as Health, Motor, Home, Fire, and Travel Insurance. ",
                "I provided high-capacity services that computed insurance risks on behalf of the company's clients. I was responsible for the conception and implementation of services that facilitated payment processing between the clients, who were legal entities, and the bank.",
                "Collaborating with my colleagues, we developed a couple of adapter modules aimed at integrating new clients into the bank's infrastructure. These modules were instrumental in the exchange of transaction data between insurance companies and legal entities. During my tenure, we successfully integrated over 10 new clients into the bank's ecosystem."
            ]
        },
        {
            logoUrl: `${hermes}`,
            companyUrl : "https://www.hermesworld.com/int/",
            title : "Software Developer",
            company : "Hermes Russia · Full-time",
            location : "Moscow City, Russia · On-site",
            period : "Jul 2017 - Oct 2019 · 2 yrs 4 mos",
            responsibilities : [
                "I contributed to the creation of a Delivery Module from the ground up. The module's primary objective was to minimize the expense associated with shipping packages from one location to another by enabling delivery companies to exchange packages at their terminals. This initiative led to an average reduction in delivery costs of 30%.",
                "I actively participated in developing multiple applications geared towards streamlining the workflow of parcel shop employees. This optimization significantly slashed the time required to process orders for customers. The enhanced user experience attracted more clients, resulting in the doubling of the client base by the time of my departure from Hermes.",
                " I was part of a project that aimed to provide a personal account system for small delivery companies lacking the capability to exchange parcel data through an API. This initiative paved the way for the inclusion of smaller suppliers into the Hermes ecosystem and ultimately drove down shipping costs.",
            ]
        },
        {
            logoUrl: `${pickpoint}`,
            companyUrl : "https://pickpoint.ru/",
            title : ".NET developer",
            company : "PickPoint · Full-time",
            location : "Moscow City, Russia · On-site",
            period : "Jun 2015 - Jul 2017 · 2 yrs 2 mos",
            responsibilities : [
                "Improved the quality and test coverage of the Parcel Terminal code base. Improved usability of the user interface to achieve business goals. Covering the system with tests made it possible to identify and fix bugs, which doubled the performance.",
                "I participated in the development of the Store Personal Account project, which facilitates shipment registration within the PickPoint system and enables users to request a courier for onward delivery. The implementation of the Store Personal Account significantly boosted delivery reliability, resulting in a remarkable 50% improvement.",
                "I contributed to the development of the Personal Account of Pickup Point project, which serves as a platform for operators to register, receive, and issue shipments, as well as generate barcode labels, and more."
            ]
        },

    ];

    return (
        <>
            <ul className="experience-list">
                {
                    positions.map(position => (
                        <li className="position-info">
                            <div className="logo-container">
                                <a target="_blank" href={position.companyUrl}>
                                    <img src={position.logoUrl}/>
                                </a>
                            </div>
                            <div className="main-info">
                                <h2 className="position-title">{position.title}</h2>
                                <a target="_blank" href={position.companyUrl}>
                                    <span className="company-title">{position.company}</span>
                                </a>
                                <span className="location">{position.location}</span>
                                <span className="period">{position.period}</span>
                                <h3>Responsibilities</h3>
                                <ul className="responsibilities">
                                    {
                                        position.responsibilities.map(resp => <li><p> { resp }</p></li>)
                                    }
                                </ul>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </>
    )
};

export default ExperienceList;