import React from "react";
import './index.scss';

const SkillLevel = ({years, projects}) => {

    const redSquares = <div className="square" color="red"></div>;
    const greenSquares = <div className="square" color="green"></div>;

    const yearsArray = Array(years).fill(redSquares);
    const projectsArray = Array(projects).fill(greenSquares);

    return (
        <div className="skill-level">
            {
                yearsArray.map((year, i) => <div key={i}>{year}</div>)
            }

            {
                projectsArray.map((project, i) => <div key={i + 30}>{project}</div>)
            }

        </div>
    );
};

export default SkillLevel;

