import React from "react";
import {Link, NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faUser, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin, faGithub} from "@fortawesome/free-brands-svg-icons";
import leetcode from "../../assets/images/leetcode.svg";
import  "./index.scss";
import Logo from "../../assets/images/logo-s.png";
import LogoSubtitle from "../../assets/images/logo_sub.png";

const Sidebar = () => {
    return (
      <div className="nav-bar">
{/*          <Link className="logo" to="/">
               <img src={Logo} alt="logo"/>
               <img src={LogoSubtitle} className="sub-logo" alt="subtitle"/>
          </Link>*/}
          <nav>
              <NavLink exact="true" activeclassname="active" to="/">
                <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>
               </NavLink>
              <NavLink exact="true" activeclassname="active" className="about" to="/about">
                  <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>
              </NavLink>
              <NavLink exact="true" activeclassname="active" className="contact" to="/contact">
                  <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"/>
              </NavLink>
          </nav>
          {/*<ul>
              <li>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/aleksei-savchenko-a62b541a1/">
                      <FontAwesomeIcon icon={faLinkedin} />
                  </a>
              </li>
              <li>
                  <a target="_blank" rel="noreferrer" href="https://github.com/alexey-savchenko-am">
                      <FontAwesomeIcon icon={faGithub} />
                  </a>
              </li>
              <li>
                  <a target="_blank" rel="noreferrer" href="https://github.com/alexey-savchenko-am">
                      <img src={leetcode} />
                  </a>
              </li>
          </ul>*/}
      </div>
    );
};

export default Sidebar;

