import React from "react";
import './index.scss';
import avatar from '../../assets/images/avatar.jpg'

const Avatar = () => {
  return (
      <div className="avatar-container">
          <img src={avatar} alt="User Avatar"/>
      </div>)
};

export default Avatar;