import React, {useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import './index.scss';
import AnimatedLetters from "../../components/AnimatedLetters";

const ContactPage = () => {

    const [letterClass, setLetterClass] = useState('text-animate');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_aaqexfk', 'template_r9v59bg', form.current, '_7A63EwWpCTMwpsAm')
            .then((result) => {
                alert('Message successfully sent!');
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again');
            });
    };

    return (
          <div className="container contact-page">
              <div className="text-zone">
                  <h1>
                      <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']} idx={15}/>
                  </h1>
              </div>
              <p>
                  I'm actively seeking software development roles.
                  Feel free to contact me through the form below if you have any opportunities.
                  Thank you!
              </p>

              <form className="contact-form" ref={form} onSubmit={sendEmail}>
                  <ul>
                      <li className="half">
                          <input type="text" name="from_name" placeholder="Name" required/>
                      </li>
                      <li className="half">
                          <input type="email" name="from_email" placeholder="Email" required/>
                      </li>
                      <li>
                          <input type="text" name="subject" placeholder="Subject" required/>
                      </li>
                      <li>
                          <textarea name="message" placeholder="Message" required></textarea>
                      </li>
                      <li>
                          <input type="submit" className="flat-button" value="send me a message"/>
                      </li>
                  </ul>
              </form>

          </div>
    );

};

export default ContactPage;