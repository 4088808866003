import React, {useEffect, useRef, useState} from "react";
import {Outlet} from "react-router-dom";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './index.scss';
import Sidebar from "../Sidebar";

const Layout = () => {

    const pageToScrollRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        const handleScroll = () => {
            if (pageToScrollRef.current.scrollTop > 20) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        pageToScrollRef.current.addEventListener('scroll', handleScroll);

        return () => {
            pageToScrollRef.current.removeEventListener('scroll', handleScroll);
        };
    });

    const scrollPageToTop = () => {
        pageToScrollRef.current.scrollTop = 0;
    };

    return (
            <div className="app">
                <Sidebar/>
                <div className="page" ref={pageToScrollRef}>
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
            </div>
    )
};

export default Layout;