import React, {useEffect, useState} from "react";
import './index.scss';
import AnimatedLetters from "../../components/AnimatedLetters";
import ExperienceList from "../../components/ExperienceList";
import SkillLevel from "../../components/SkillLevel";
import cv from "../../assets/asavchenko-cv.pdf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faStar, faLightbulb} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import net from "../../assets/images/skills/net.png";
import sharp from "../../assets/images/skills/sharp.png";
import mssql from "../../assets/images/skills/mssql.png";
import node from "../../assets/images/skills/node.png";
import react from "../../assets/images/skills/react.png";
import ts from "../../assets/images/skills/ts.png";
import mysql from "../../assets/images/skills/mysql.png";
import postgre from "../../assets/images/skills/postgre.png";
import redis from "../../assets/images/skills/redis.png";
import mongo from "../../assets/images/skills/mongo.png";
import oracle from "../../assets/images/skills/oracle.png";
import gpdb from "../../assets/images/skills/gpdb.png";
import aws from "../../assets/images/skills/aws.png";
import azure from "../../assets/images/skills/azure.png";
import kafka from "../../assets/images/skills/kafka.webp";
import rabbitmq from "../../assets/images/skills/rabbitmq.webp";


const AboutPage = () => {

    const [letterClass, setLetterClass] = useState('text-animate');
    const [skillsDictionary, setSkills] = useState({
        net: {
            name: 'Backend Development',
            years: 11,
            projects: 9,
            icons: [sharp, net],
            description: 'I possess strong skills in C#, with expertise in microservices architecture, Domain-Driven Design (DDD), and Test-Driven Development (TDD). My proficiency in C# allows me to design and develop robust microservices, adhering to best practices, and ensuring efficient communication between services. My knowledge of DDD enables me to model complex domains effectively, ensuring a clean and maintainable codebase. Through TDD, I prioritize quality, identifying and addressing issues early in the development process. These combined skills enable me to deliver scalable, maintainable, and high-quality software solutions in the world of microservices, aligning with best industry standards.'
        },
        databases: {
            name: 'Databases',
            years: 11,
            projects: 9,
            icons: [mssql, mysql, postgre, gpdb, oracle],
            description: 'I have extensive skills in relational databases, including MySQL, MS SQL, and PostgreSQL. With a strong foundation in database design and management, I have successfully implemented and maintained complex database systems across various projects. My proficiency extends to data modeling, query optimization, and ensuring data integrity. I\'ve leveraged MySQL\'s scalability, MS SQL\'s enterprise capabilities, and PostgreSQL\'s open-source power to cater to diverse project requirements. My expertise encompasses data migration, performance tuning, and developing efficient database-driven applications. I am adept at crafting robust and secure solutions, ensuring the seamless operation of database systems across different platforms.'
        },
        nosql: {
            name: 'NoSQL',
            period: '10 years, 5 projects',
            years: 4,
            projects: 4,
            icons: [redis, mongo],
            description: 'Proficient in NoSQL databases, I have expertise in Redis and MongoDB. With Redis, I excel in building high-performance, in-memory data stores for caching and real-time analytics. My skills extend to configuring Redis clusters, optimizing data structures, and implementing efficient caching strategies. Additionally, I have honed my abilities in MongoDB, adept at designing and maintaining scalable, document-based databases. My experience includes schema design, query optimization, and ensuring data integrity. These skills enable me to deliver robust and efficient data solutions for a wide range of applications, making me a valuable asset in the realm of NoSQL databases.'
        },
        messageBrokers: {
            name: 'Message Brokers',
            years: 5,
            projects: 7,
            icons: [kafka, rabbitmq],
            description: 'Specialized in message brokers, I\'ve harnessed the power of RabbitMQ and Kafka to optimize microservice architecture. My expertise lies in architecting, configuring, and deploying these systems to facilitate seamless communication among microservices. By effectively managing queues, topics, and routing, I\'ve ensured reliable data exchange within complex projects. These skills have empowered me to design robust, event-driven applications, enabling efficient data flow and synchronization across distributed components. Leveraging my proficiency in message brokers, I\'ve contributed to enhancing the scalability, fault tolerance, and real-time capabilities of applications, fostering dynamic and responsive microservice ecosystems.'
        },
        cloudPlatforms: {
            name: 'Cloud Solutions',
            years: 4,
            projects: 3,
            icons: [aws, azure],
            description: 'I possess a strong skill set in cloud solutions, specializing in both Amazon Web Services (AWS) and Azure Stack. My expertise spans a wide range of cloud services, including infrastructure provisioning, container orchestration, serverless computing, and data analytics. I have a proven track record in architecting and implementing scalable and cost-effective cloud solutions, enabling businesses to leverage the full potential of the cloud for improved agility and efficiency. My skills extend to security and compliance, ensuring data integrity and regulatory adherence. I am well-versed in hybrid cloud strategies, effectively bridging on-premises and cloud environments.'
        },
        front: {
            name: 'Frontend Development',
            years: 5,
            projects: 2,
            icons: [react, node, ts],
            description: 'As a primarily backend developer, I\'ve ventured into the realm of frontend development, specifically with a focus on technologies like React and Redux. I\'ve honed my skills in crafting intuitive user interfaces, designing responsive web applications, and ensuring seamless user experiences. My proficiency extends to crafting efficient and maintainable code, leveraging React\'s component-driven architecture and Redux for state management. Despite my backend background, I\'ve demonstrated my adaptability and dedication to mastering frontend technologies. My ability to bridge the gap between backend and frontend development allows me to create well-rounded, full-stack solutions that deliver high-quality user experiences.'
        }
    });
    const [selectedSkill, setSelectedSkill] = useState('net');

    const [quote, setQuote] = useState("\"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.\n" +
        "And the only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.\" - Steve Jobs");

    useEffect(() => {

        const apiUrl = 'https://api.api-ninjas.com/v1/quotes?category=computers';
        const headers = {
            'Content-Type': 'application/json',
            'X-Api-Key': 'ibNsX7+oySKyeAI9jf1EVg==6OVMzlhdRQjQ9X0k'
        };
        const fetchData = () => {
            fetch(apiUrl, { method: 'GET', headers: headers})
                .then(response => response.json())
                .then(data => {
                    setQuote(`"${data[0].quote}" - ${data[0].author}`);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };
        //const intervalId = setInterval(fetchData, 10000);
        //return () => clearInterval(intervalId);
    }, []);

    const displaySkillDescription = (key) => {
        setSelectedSkill(key);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="about-page">

            <h1>
                <AnimatedLetters letterClass={letterClass} strArray={ ['M', 'y', ' ', 's', 'k', 'i', 'l', 'l', 's']} idx={10}/>
            </h1>

            <p className="quote">

                {
                    quote
                }

            </p>

            <div className="skills">
                <Slider className="skills-slider" {...settings}>
                    {
                        Object.keys(skillsDictionary).map(key => (
                            <div key={key} className = {`skill ${key === selectedSkill ? 'active': ''}`} onClick={() => displaySkillDescription(key)}>
                                <h3 className="skill-name">{skillsDictionary[key].name}</h3>
                                <span className="skill-period">{skillsDictionary[key].years} years, {skillsDictionary[key].projects} projects</span>
                                <SkillLevel years={skillsDictionary[key].years} projects={skillsDictionary[key].projects} />
                                <div className="icons">
                                    {
                                        skillsDictionary[key].icons && skillsDictionary[key].icons.map((icon, i) => (<img key={i} src={icon} alt="csharp"/>))
                                    }
                                </div>
                                <div className = "description active">
                                    <span>{skillsDictionary[key].description}</span>
                                </div>

                            </div>
                        ))
                    }
                </Slider>
            </div>



            <h1>
                <AnimatedLetters letterClass={letterClass} strArray={['E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e']} idx={23}/>
            </h1>

            <div className="experience">
                <a
                    className="link-button"
                    href={cv}
                    download="Aleksei Savchenko CV.pdf"
                >
                    Download CV (PDF 53 kb) <FontAwesomeIcon className="icon" size="sm" icon={faDownload} />
                </a>
                <ExperienceList/>
            </div>
        </div>
    )
};

export default AboutPage;