import React, {useState} from "react";
import "./index.scss";
import {Link} from "react-router-dom";
import Loader from "react-loaders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import AnimatedLetters from "../../components/AnimatedLetters";
import {
    faMicrosoft,
    faCss3,
    faHtml5,
    faJsSquare,
    faReact,
    faGithub, faLinkedin

} from "@fortawesome/free-brands-svg-icons";
import cv from '../../assets/asavchenko-cv.pdf'
import Logo from "../../components/Logo";
import Avatar from "../../components/Avatar";


const HomePage = () => {

    const [letterClass, setLetterClass] = useState('text-animate');
    const nameArray = ['A', 'l', 'e', 'k', 's', 'e', 'i', ' ', 'S', 'a', 'v', 'c', 'h', 'e', 'n', 'k', 'o'];

    return (
        <div className="container home-page">
            <div className="text-zone">

                <span className="greet">Hi, my name is</span>
                <h1 className="name">
                   <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                </h1>
                <h2 className="position">
                    Software Craftsman
                </h2>

                <p className="description">
                    <Avatar/>
                </p>
                <p className="description2">
                     My expertise extends to a wide range of technologies and tools, including <strong>.NET/C#</strong>, <strong>Docker/Kubernetes</strong> for
                    containerization and orchestration, and <strong>REST/SOAP</strong> for building robust APIs.
                    I am proficient in database management, with hands-on experience in <strong>MS SQL</strong>, <strong>MySQL</strong>, <strong>PostgreSQL</strong>, <strong>GreenplumDB</strong>,
                    and NoSQL databases like <strong>Redis</strong>, <strong>MongoDB</strong>, and <strong>RabbitMQ/Kafka</strong> for message queuing.
                    My cloud computing skills are honed through working with <strong>Azure</strong>, where I've implemented and managed cloud-based solutions.
                    While I'm primarily a server-side developer, I have a deep passion for front-end development, particularly in building dynamic and responsive user interfaces using the <strong>React JS</strong> framework.
                </p>
                <div className="actions">
                    <a className="link-button" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/aleksei-savchenko-a62b541a1/">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>

                    <a className="link-button" target="_blank" rel="noreferrer" href="https://github.com/alexey-savchenko-am">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>

                    <a className="link-button" target="_blank" rel="noreferrer" href="https://www.nuget.org/profiles/goOrn">
                        <FontAwesomeIcon icon={faMicrosoft} />
                    </a>

                </div>
                <div className="actions">
                    <Link to="/contact" className="flat-button">Contact Me</Link>

                    <a
                        className="flat-button"
                        href={cv}
                        download="Aleksei Savchenko CV.pdf"
                    >
                        Download CV <FontAwesomeIcon className="icon" size="sm" icon={faDownload} color="#ffd700" />
                    </a>

                </div>
            </div>
        </div>
    );
}

export default HomePage;